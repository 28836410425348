<template>
  <div>
    <div>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="考题名称" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.name"
            placeholder="请输入考题名称"
            @input="reloadCompanQuestionData"
          ></el-input>
        </el-form-item>

        <el-form-item label="学科" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.subjectType"
            placeholder="请选择学科"
            @change="reloadCompanQuestionData"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" @click="reloadCompanQuestionData"
            >查询</el-button
          >
          <el-button v-throttle @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-table
        v-loading="loading"
        border
        :data="questionDataList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="sortNum"
          label="序号"
          show-overflow-tooltip
          :min-width="20"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="考题名称"
          show-overflow-tooltip
          :min-width="400"
        ></el-table-column>
        <el-table-column
          prop="subjectType"
          label="学科"
          show-overflow-tooltip
          :min-width="30"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.subjectType == 1">物理</span>
            <span v-else-if="scope.row.subjectType == 2">生物</span>
            <span v-else-if="scope.row.subjectType == 3">化学</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="score"
          label="总分"
          show-overflow-tooltip
          :min-width="25"
        ></el-table-column>
        <el-table-column label="操作" min-width="40px" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="primary"
              @click="questionInfo(scope.row.operationTestId)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageParam.pageIndex"
        :page-size="pageParam.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageParam.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getCompanQuestionDataApi } from '@/api/schoolManufacturer/questionData.js'
export default {
  name: 'questionData',
  data() {
    return {
      queryParam: {
        name: '',
        subjectType: '',
      },
      loading: false,
      questionDataList: [],
      pageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      subjectOptions: [],
    }
  },
  mounted() {
    this.renderSelect()
    this.init()
  },
  methods: {
    renderSelect() {
      this.subjectOptions = this.$Global.globalSubjectOptions
    },
    init() {
      this.getCompanQuestionData()
    },
    //获取考题数据-分页
    getCompanQuestionData() {
      this.loading = true
      getCompanQuestionDataApi(
        this.pageParam.pageIndex,
        this.pageParam.pageSize,
        this.queryParam
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.questionDataList = []
            this.questionDataList = res.data
            this.questionDataList.forEach((item, index) => {
              item.sortNum = index + 1
            })
            this.pageParam.total = 0
            this.pageParam.total = res.total
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    reloadCompanQuestionData() {
      this.queryParam.pageIndex = 1
      this.getCompanQuestionData()
    },
    refresh() {
      this.queryParam.name = ''
      this.queryParam.subjectType = ''
      this.reloadCompanQuestionData()
    },
    handleCurrentChange(val) {
      this.pageParam.pageIndex = val
      this.getCompanQuestionData()
    },
    handleSizeChange(val) {
      this.pageParam.pageSize = val
      this.getCompanQuestionData()
    },
    //查看考题详情
    questionInfo(val) {
      this.$router.push({
        path: '/schoolManufacturer/questionInfo',
        query: { operationTestId: val },
      })
    },
  },
}
</script>

<style></style>
